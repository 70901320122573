<template>
  <div id="component-list-center" class="align-self-end">
    <div class="card p-1 card-border">
      <div class="card-body">
        <select class="form-control" v-on:click="emitSelectCenter">
          <option disabled :selected="!selectedCenter" :value="null">Choisir un Centre</option>
          <option
              v-for="(center, index) in CenterModule.centers" :key="index"
              class="d-flex p-2 c-pointer"
              :value="center.id"
          >
            {{ center.name }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      selectedCenter: null
    }
  },
  methods: {
    emitSelectCenter($event) {
      this.selectedCenter = $event.target.value;
      this.$emit('emit-select-center', $event)
    },
  },
  created() {
    this.$store.dispatch('fetchCenters', {
      perPage: null,
      page: null
    })
  },
  computed: {
    ...mapState(['CenterModule']),
  },
}
</script>

<style scoped>

</style>
